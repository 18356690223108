export async function sendEmail (data) {
  const response = await fetch('send.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      name: data.name,
      email: data.email,
      phone: data.phone,
      note: data.note
    })
  })
  return await response.text()
}
